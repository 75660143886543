import React, { useEffect, useState } from 'react';
import { Payment } from '@sworks-platform/gp001-payment-web';
import styles from './CheckOut.module.scss';
import axios from 'axios';
import Swal from 'sweetalert2';
import { themeStyling } from '../../shared/providers/ThemeStyler';
import Loader from '../Loader/Loader';
import { getDomainConfigurations } from '../../shared/providers/services/domain.service.js';
import PaymentConfirm from '../BookingConfirm/paymentConfirm';
import { getTimeZone } from '../../shared/providers/Utils';

const CheckOut = ({ ...props }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const o_id = urlParams.get('o_id');
  const u_id = urlParams.get('u_id');
  const u_token = urlParams.get('u_token');
  const [cabBooked, setCabBooked] = useState(false);
  const [confirmPage, setConfirmPage] = useState(false);
  const [onLoadData, setOnLoadData] = useState(false);
  const [stripeConfig, setStripeConfig] = useState();
  const [userData, setuserData] = useState();
  let domainConfiguration = JSON.parse(localStorage.getItem('domainConfiguration'));
  let details = {};

  useEffect(() => {
    getDomainConfiguration();
  }, []);

  async function getDomainConfiguration() {
    const domain = await getDomainConfigurations();
    domainConfiguration = domain;
    if (domainConfiguration) {
      details = {
        hostUrl: process.env.REACT_APP_API_HOST_URL,
        userId: u_id,
        userToken: `Bearer ${u_token}`,
        stripeKey: domainConfiguration.stripeKey,
        tenantId: domainConfiguration.tenantId,
      };
      setStripeConfig(details);
      setuserData(details);
      themeStyling(domainConfiguration?.webConfigs?.theme?.primaryColor, domainConfiguration?.webConfigs?.theme?.name);
      setOnLoadData(true);
    }
  }

  async function onSuccess(data) {
    if (data?.paymentMethod) {
      if (o_id) {
        const confirmData = await confirmCardOrder(o_id, data?.paymentMethod, data?.customerId);
        if (confirmData.status === 1) {
          setConfirmPage(true);
          window.history.replaceState({}, document.title, '/');
        } else {
          failPopup(confirmData.error.errorMessage);
        }
      }
    }
  }

  async function onFail(error) {
    failPopup(error);
  }

  function failPopup(error) {
    const domainConfiguration = JSON.parse(localStorage.getItem('domainConfiguration'));
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error,
      confirmButtonColor: domainConfiguration.webConfigs.theme.primaryColor,
    });
  }

  const confirmCardOrder = async (orderId, txnId, customerId) => {
    setCabBooked(true);
    try {
      const url = `${process.env.REACT_APP_API_HOST_URL}/order/${orderId}`;
      let requestConfig = {
        method: 'PATCH',
        url,
        headers: {
          authorization: userData.userToken,
          tenantid: userData.tenantId,
          userId: userData.userId,
          'response-version': `v2`,
          'x-sworks-timezone': getTimeZone(),
        },
      };
      const data = {
        userData: { stripeCustomerId: customerId },
        payment: {
          method: 'CARD',
          payeeCardId: txnId,
        },
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const result = await axios.patch(url, data, requestConfig);
      setCabBooked(false);
      localStorage.removeItem('bookingResponse');
      return result.data;
    } catch (error) {
      setCabBooked(false);
      localStorage.removeItem('bookingResponse');
      throw error;
    }
  };

  return (
    <>
      {cabBooked ? <Loader /> : <></>}
      {onLoadData ? (
        <>
          {confirmPage ? (
            <PaymentConfirm
              data={domainConfiguration?.webConfigs?.feature?.booking?.onConfirm}
              urls={domainConfiguration.url}
            ></PaymentConfirm>
          ) : (
            <div className={`${styles.paymentBox}`}>
              <Payment stripeDetails={stripeConfig} onSucess={onSuccess} onFail={onFail}></Payment>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default CheckOut;
